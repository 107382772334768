// Style Imports
@import 'framework/_modern-normalize';
@import 'framework/_mixins';
@import 'framework/_fonts';
@import '../../../node_modules/react-alice-carousel/lib/scss/alice-carousel.scss';

// General
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: subpixel-antialiased;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $grey;
}
::-moz-placeholder { /* Firefox 19+ */
    color: $grey;
}
:-ms-input-placeholder { /* IE 10+ */
    color: $grey;
}
:-moz-placeholder { /* Firefox 18- */
    color: $grey;
}

html, body {
    @include font();
    @include rem('font-size', 16);
    color: $default;
    line-height: calc(1.5em + .2vw);
    &.is-open {
        overflow: hidden !important;
    }
}

img {
    max-width: 100%;
    display: block;
}

a {
    @include trans();
    text-decoration: none;
    color: $black;
    &:hover {
        color: $black;
    }
}

b, strong {
    @include font($weight: 700);
}

.sr-only {
    display: none;
}

.show-on-focus {
    position: absolute;
    right: 20px;
    top: 28px;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    &:focus {
        z-index: 20;
        width: auto;
        height: auto;
        clip: auto;
    }
}

// Headings
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1.2;
    font-weight: 400;
}

h1 {
    margin: 20px 0;
    @include rem('font-size', 32);
    text-align: center;
    display: block;
    width: 100%;
}

h2, h3 {
    @include rem('font-size', 19);
}

h4 {
    @include rem('font-size', 18);
}

h5 {
    @include rem('font-size', 26);
}

// General
.wrapper {
    max-width: 990px;
    width: 100%;
    margin: auto;
    position: relative;
    @include clearfix;
}

@media screen and (min-width: 760px) and (max-width:1160px) {
    main {
        .wrapper {
            padding: 0 20px;
        }
    }
}

main {
    position: relative;
    z-index: 20;
}

.half-image {
    float: left;
    width: 50%;
    text-align: center;
    padding: 0;
    margin: 20px 0 40px;
    img {
        margin: auto;
    }
    + p {
        clear: both;
    }
}

.one-third-image {
    text-align: center;
    padding: 0;
    margin: 20px 0 40px;
    @include clearfix;
    img {
        margin: auto;
        float: left;
        width: calc(100% / 3);
    }
    + p {
        clear: both;
    }
}


@media screen and (min-width: 768px) {
    .half {
        float: left;
        width: 50%;
    }
}

// Loader
.dimmer.visible {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
}
.ui.dimmer {
    z-index: 9 !important;
}

// Main
.pushable:not(body) {
    transform: none;
}

.ui.segment {
    background: none;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    border: 0 none;
}

// Buttons
.blk-btn {
    background: $black;
    color: $white;
    @include rem('font-size', 24);
    width: 80%;
    margin: 0 10%;
    display: block;
    text-align: center;
    padding: 10px;
    &:hover {
        color: $white;
        background: #666;
    }
}

.red-btn {
    background: $red;
    color: $white;
    margin: 20px auto;
    display: inline-block;
    text-align: center;
    padding: 10px;
    border: 1px solid $red;
    &:hover {
        color: $red;
        background: $white;
    }
}

.loading-article {
    margin-top: 75px;
    text-align: center;
}

.follow-me-btn {
    @include rem('font-size', 16);
    color: $white;
    background: $black;
    border: 0 none;
    text-transform: uppercase;
    padding: 8px 20px;
    border-radius: 5px;
    transition: all linear .5s;
    cursor: pointer;
    display: block;
    margin: auto;
    line-height: 1;
    &:hover {
        color: $white;
        background: $red;
    }
}

.notifications-articles {
    display: grid;
    grid-template-columns: repeat(2, calc((100% - 20px) / 2));
    gap: 0px 20px;
    margin-top: 50px;
    @media screen and (max-width: 1023px) {
        grid-template-columns: 100%;
        padding: 0 20px;
    }
    article {
        padding-bottom: 1.25rem;
        margin-bottom: 1.25rem;
        border-width: 0px 0px 1px;
        border-style: solid;
        border-color: rgb(212, 212, 212);
        a {
            display: grid;
            gap: 1.25rem;
            grid-template-columns: minmax(min-content, calc(100% - 100px)) 80px;
        }
        h6 {
            font-size: 1rem;
            margin-top: 0px;
            margin-bottom: 0.5rem;
        }
        p {
            font-size: 0.75rem;
            margin-bottom: 0;
            line-height: 1.2;
        }
    }
}

.notifications-section-h2 {
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: rgb(212, 212, 212);
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-transform: capitalize;
    .follow-me-btn {
        margin: 0;
    }
    @media screen and (max-width: 1023px) {
        flex-direction: column;
        margin: 20px;
        width: auto;
        .follow-me-btn {
            margin-top: 10px;
        }
    }
}

.notifications-list {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 5px 20px;
    margin-bottom: 30px;
    @media screen and (max-width: 1023px) {
        grid-template-columns: 100%;
        gap: 5px;
        padding: 0 20px;
    }
    > div {
        background-color: rgb(247, 247, 247);
        padding: 10px 15px;
        text-transform: capitalize;
        > label {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C4C4C4;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    border: 1px solid transparent;
    &::after {
        content: "OFF";
        font-size: 10px;
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translatey(-50%);
    }
    &::before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 1px;
        top: 1px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
    }
}

input:checked+.slider {
    background-color: #15CF74;
}

input:focus+.slider {
    border: 1px solid #000;
}

input:checked+.slider {
    &::after {
        content: "ON";
        left: 9px;
        color: #3E6C56;
    }
    &::before {
        transform: translateX(26px);
    }
}

.push-notification {
    display: grid;
    grid-template-columns: 56px auto;
    @include rem('gap', 20);
    img {
        width: 14rem;
    }
    div {
        color: #434343;
        > div {
            display: flex;
            justify-content: flex-end;
        }
    }
    p {
        margin: 0;
        line-height: 1.4;
        &:first-of-type {
            @include rem('font-size', 17);
        }
        &:last-of-type {
            margin-bottom: 0.9375rem;
        }
    }
    a {
        display: inline-block;
        padding: 6px 20px;
        background-color: rgb(255, 255, 255);
        color: rgb(67, 67, 67);
        font-family: sans-serif;
        line-height: normal;
        text-transform: uppercase;
    }
    button {
        padding: 6px 20px;
        border-radius: 0.25rem;
        border: 0 none;
        cursor: pointer;
        &:first-of-type {
            background-color: rgb(255, 255, 255);
            color: rgb(67, 67, 67);
        }
        &:last-of-type {
            background-color: rgb(46, 95, 220);
            color: rgb(255, 255, 255);
            margin-left: 0.3125rem;
        }
    }
}

.skeleton {
    > div > div {
        background: #F0F0F0;
        border-radius: 3px;
        width: 100%;
        display: block;
        margin: auto;
    }
    img {
        margin-bottom: 20px;
        border: 2px solid #FFF;
    }
    .p {
        height: 10px;
        margin-bottom: 5px;
        &-short {
            width: 33%;
        }
    }
    .title {
        height: 30px;
        margin-bottom: 5px;
        &-short {
            width: 33%;
        }
    }
    h1 {
        background: #FFF;
        height: 30px;
        margin-bottom: 20px !important;
        display: block;
        width: 50%;
        margin: auto;
    }
    .label {
        height: 7px;
        margin-bottom: 5px;
        width: 33%;
    }
}

.article-list article.skeleton img {
    margin-bottom: 0;
}

@import 'modules/_header';
@import 'modules/_footer';
@import 'modules/_adverts';
@import 'modules/_article-list';
@import 'modules/_sidebar';
@import 'modules/_main-article';
@import 'modules/_static-pages';
@import 'modules/_content-hub';
@import 'modules/_cookie-consent';