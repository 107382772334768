// Content Hub
.content-hub {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 66% 34%;
    }
    margin-bottom: 30px;
    background: #f1f1f1;
    article {
        p {
            margin: 0;
        }
        img {
            @include trans($style: ease-in-out);
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
    .content-hub-main {
        position: relative;
        @media screen and (min-width: 768px) {
            overflow: hidden;
        }
        img {
            width: 100%;
        }
        .content {
            position: absolute;
            bottom: -20%;
            width: 90%;
            @include center(x);
            text-align: center;
            background: $white;
            z-index: 10;
            box-shadow: 0 0 3px rgba(0,0,0,.1);
            border: 2px solid $white;
            padding: 20px 10px;
            @include trans($style: ease-in-out);
            transition: all .3s ease-in-out;
            @media screen and (min-width: 768px) {
                bottom: 30px;
                padding: 20px;
            }
            &::before {
                content: "";
                display: block;
                border: 2px solid #fff;
                height: 30px;
                width: 30px;
                background: #fff;
                position: absolute;
                left: 0;
                top: -17px;
                right: 0;
                margin: auto;
                transform: rotate(45deg);
                @include trans($style: ease-in-out);
                border-right: none;
                border-bottom: none;
                z-index: 2;
            }
            h4 {
                @include rem('font-size', 24);
                font-weight: 700;
                margin-bottom: 20px;
            }
            p {
                @include rem('font-size', 12);
                text-transform: uppercase;
                margin: 0;
                line-height: 1.8;
                &:last-of-type {
                    @include rem('font-size', 10);
                }
            }
        }
        &:hover {
            .content {
                background: $black;
                color: $white;
                &::before {
                    background: $black;
                }
            }
        }
    }
    .content-hub-round {
        background: #f1f1f1;
        padding-top: 70px;
        position: relative;
        @media screen and (min-width: 768px) {
            padding-top: 0;
            margin-top: 0;
            height: 100%;
            display: grid;
            grid-template-rows: 1fr 1fr 1fr 1fr;
        }
        article {
            position: relative;
            @include trans();
            display: grid;
            align-items: center;
            &:hover {
                background: #e6e6e6;
            }
            &::after {
                content: '';
                background: #e6e6e6;
                height: 1px;
                width: 80%;
                display: block;
                margin: auto;
                position: absolute;
                bottom: 0;
                @include center(x);
            }
            &:last-of-type {
                &::after {
                    display: none;
                }
            }
            .content {
                align-self: center;
            }
        }
        article {
            h4 {
                @include rem('font-size', 16);
                font-weight: 700;
            }
            a {
                padding: 20px;
                display: grid;
                grid-template-columns: 35% 65%;
            }
        }
        .article-list-img {
            img {
                width: 80px;
                height: auto;
                border-radius: 100%;
            }
        }
    }
}

.content-hub-split {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;
    }
    article {
        border: 1px solid rgba(0,0,0,.1);
        margin-bottom: 30px;
        text-align: center;
        overflow: hidden;
        .article-list-img {
            overflow: hidden;
            img {
                display: block;
                margin: auto;
                width: 100%;
                height: auto;
            }
        }
        img {
            @include trans($style: ease-in-out);
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
        @media screen and (min-width: 768px) {
            a {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
            }
            &:last-of-type {
                .article-list-img {
                    grid-row: 1 / 1;
                    grid-column: 1 / 1;
                }
                .content {
                    grid-row: 1 / 1;
                    grid-column: 2 / 2;
                }
            }
        }
        h4 {
            @include rem('font-size', 24);
            font-weight: 700;
            margin-bottom: 20px;
            padding: 20px;
            display: block;
            @include trans();
            &:hover {
                background: $black;
                color: $white;
            }
        }
        p {
            margin: 0;
            @include rem('font-size', 12);
            text-transform: uppercase;
            line-height: 1.8;
            &:last-of-type {
                @include rem('font-size', 10);
                @media screen and (max-width: 767px) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.content-hub-list {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(3, calc((100% - 345px) / 3)) 300px;
    }
    aside {
        @media screen and (min-width: 768px) {
            grid-column: 4 / 4;
            grid-row: 1 / span 2;
        }
        div {
            margin: 0;
        }
    }
    .more-from-btn {
        @media screen and (min-width: 768px) {
            grid-column: 1 / span 4;
        }
        padding: 0;
        margin: 0;
    }
    article {
        border: 1px solid #EEE;
        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        .article-list-img {
            overflow: hidden;
        }
        img {
            @include trans($style: ease-in-out);
            width: 100%;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
        h4 {
            @include rem('font-size', 14);
            font-weight: 700;
            margin-bottom: 15px;
        }
        p {
            @include rem('font-size', 11);
            margin: 0;
            line-height: 1.8;
            text-transform: uppercase;
            &:last-of-type {
                @include rem('font-size', 10);
            }
        }
        .content {
            padding: 15px;
            @media screen and (max-width: 767px) {
                text-align: center;
            }
        }
        a {
            @include trans($style: ease-in-out);
        }
        &:hover {
            background: $black;
            a {
                color: $white;
            }
        }
    }
}

.content-hub-more {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(4, calc((100% - 45px) / 4));
        h1 {
            grid-column: 1 / span 4;
        }
        .more-from-btn {
            grid-column: 1 / span 4;
            padding: 0;
            margin: 0;
        }
    }
    article {
        border: 1px solid #EEE;
        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        .article-list-img {
            overflow: hidden;
        }
        img {
            @include trans($style: ease-in-out);
            width: 100%;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
        h4 {
            @include rem('font-size', 14);
            font-weight: 700;
            margin-bottom: 15px;
        }
        p {
            @include rem('font-size', 11);
            margin: 0;
            line-height: 1.8;
            text-transform: uppercase;
            &:last-of-type {
                @include rem('font-size', 10);
            }
        }
        .content {
            padding: 15px;
            @media screen and (max-width: 767px) {
                text-align: center;
            }
        }
        a {
            @include trans($style: ease-in-out);
        }
        &:hover {
            background: $black;
            a {
                color: $white;
            }
        }
    }
}