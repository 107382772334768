// Article List
@media screen and (min-width: 769px) {

    .article-listing,
    .article-page {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: calc(100% - 320px) 300px;
    }

    .article-page {
        margin-bottom: 20px;

        article {
            display: block;
            width: 100%;
        }
    }

    .sidebar {
        grid-column: 2 / span 1;
    }

    .main-content {
        grid-column: 1 / span 1;
        padding: 0 20px;
    }
}

@media screen and (max-width: 1160px) {
    .article-page {
        padding: 0 20px;
    }
}

.articles-list {
    @media screen and (min-width: 768px) {
        grid-column: 1 / span 1;
        // padding: 0 20px;
    }

    h1,
    h2 {
        @include rem('font-size', 32);

        @media screen and (min-width: 768px) {
            @include rem('font-size', 40);
        }

        font-weight: 400;
        text-transform: uppercase;
        text-align: left;
        margin: 20px 0;
    }

    h2:last-of-type {
        @include rem('font-size', 32);
    }

    .article-list {
        margin-bottom: 40px;
    }
}

.article-list {
    background: $ltgrey;
    padding: 20px;

    @media screen and (min-width: 769px) {
        // max-width: calc(100% - 320px);
        grid-column: 1 / span 1;
    }

    @media screen and (min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // float: left;
    }

    width: 100%;

    article {
        margin: 20px 0;
        word-wrap: break-word;
        text-align: center;
        font-weight: 700;
        background: $white;

        @media screen and (min-width: 768px) {
            width: calc((100% - 20px) / 2);
            margin: 0;
            margin-right: 20px;
            margin-bottom: 20px;

            &:nth-of-type(2n+1) {
                margin-right: 0;
            }
        }

        &:first-of-type {
            width: 100%;
            margin-right: 0;
            background: none;

            .content {
                background: $white;
                margin: auto;
                margin-top: -40px;
                position: relative;
                padding: 10px;
                width: 90%;

                @media screen and (min-width: 768px) {
                    width: 80%;
                }

                p,
                h4 {
                    margin: 0;
                }
            }
        }

        .content {
            background: $white;
            padding: 10px;

            img {
                margin-bottom: 5px;
            }
        }

        a {
            color: $default;

            &:hover {
                color: $default;
            }
        }

        p {
            margin: 0;

            a {
                color: $red;
            }
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
        }

        +.advert-leaderboard-top>div {
            padding-top: 0;
        }

        &:last-of-type,
        &:nth-last-of-type(2) {
            margin-bottom: 0;
        }
    }

    >div {
        max-width: 1040px;
        width: 100%;
        margin: auto;
    }

    &.paged {
        article {
            &:first-of-type {
                @media screen and (min-width: 768px) {
                    width: calc((100% - 60px) / 2);
                }

                .content {
                    margin-top: 0;
                    width: 100%;
                }
            }

            @media screen and (min-width: 768px) {
                width: calc((100% - 60px) / 2);
                margin: 0;
                margin-right: 30px;
                margin-bottom: 20px;

                &:nth-of-type(2n+1) {
                    margin-right: 30px;
                }

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    h4 {
        @include rem('font-size', 25);
    }

    .follow-me-btn {
        margin-bottom: 30px;
    }
}

.author.article-list {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: $white;
    text-align: center;

    .author-bio {
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 20px;
        margin-bottom: 50px !important;
        margin: 0 auto;

        img {
            border-radius: 50%;
            width: 150px;
            height: 150px;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        p {
            padding-left: 20px;
            padding-right: 20px;
        }

        &:after {
            content: "";
            display: block;
            margin: 0 auto;
            width: 30%;
            padding-top: 20px;
            border-bottom: 5px solid $red;
        }

        &.no-divider::after {
            display: none;
            visibility: hidden;
        }
    }

    .follow-me-btn {
        margin-bottom: 10px;
    }
}

// More From Btn
.more-from-btn {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    margin-top: 20px;

    button,
    a {
        background: $default;
        color: $white;
        border: 0 none;
        padding: 20px;
        width: 260px;
        cursor: pointer;
        @include trans();

        &:hover {
            background: $red;
        }
    }
}

// Offline message
.offline {
    svg {
        vertical-align: middle;
        margin-right: 10px;
    }
}

// Search Results
.article-list-full {
    @media screen and (min-width: 769px) {
        // max-width: calc(100% - 320px);
        grid-column: 1 / span 1;
    }

    @media screen and (min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // float: left;
    }

    width: 100%;

    article {
        margin: 20px 0;
        word-wrap: break-word;

        @media screen and (min-width: 768px) {
            width: calc((100% - 90px) / 4);
            margin: 0;
            margin-right: 30px;
            margin-bottom: 20px;

            &:nth-of-type(4n) {
                margin-right: 0;
            }
        }

        padding-bottom: 20px;
        border-bottom: 1px solid $default;

        p {
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
        }
    }
}

.no-results {
    text-align: center;

    h1 {
        text-align: center;
    }

    img {
        margin: 20px auto;
    }

    p {
        @include rem('font-size', 20);
    }
}

// Search Results
.search-page {
    margin-top: 20px;

    h1 {
        @include rem('font-size', 20);
        margin-bottom: 20px;
    }
}

.search-results {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(4, calc((100% - 60px) / 4));
        grid-gap: 20px;
    }

    h3 {
        border-bottom: 0 none;
        text-transform: none;
    }
}

// Full Width
@media screen and (min-width: 768px) {
    .full-article-list {
        .article-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            article {
                width: 100%;

                &:first-of-type {
                    grid-column: 1 / span 2;
                    width: 80%;
                    margin: auto;
                }
            }

            h1,
            .more-from-btn {
                grid-column: 1 / span 2;
            }

            .more-from-btn {
                margin: 0;
                padding: 0;
            }
        }

        &.malfy {
            .article-list {
                article {
                    margin: 0;
                    margin-right: 30px;
                    margin-bottom: 20px;

                    &:first-of-type {
                        background: #FFF;
                        grid-column: 1 / span 1;
                        width: 100%;

                        .content {
                            width: 100%;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

.beauty-awards {
    .category-container {
        position: relative;
        background-color: #000;
        padding: 20px 10px;

        .scroll-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color:  rgba(255, 255, 255, 0.65); // changed to semi-transparent white
            color: rgba(0, 0, 0, 0.5); // arrow color is now semi-transparent black
            border: none;
            width: 50px; // set a fixed width
            height: 50px; // set a fixed height
            line-height: 50px; // centering the arrow vertically
            text-align: center; // centering the arrow horizontally
            cursor: pointer;
            z-index: 1;
            visibility: visible;
            border-radius: 50%; // keeps the button round
            font-size: 1.5rem; // makes the arrow symbols bigger
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // adding a slight shadow

            &.left-btn {
                left: 10px;
            }

            &.right-btn {
                right: 10px;
            }
        }

        h2 {
            color: #ffffff;
            font-size: 24px;
            text-align: center;
            margin-bottom: 20px;
        }

        ul {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow-x: auto;
            white-space: nowrap;

            &::-webkit-scrollbar {
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        .category-item {
            text-align: center;
            margin: 0 10px;
            flex: none;

            &:hover span::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px; // height of the underline
                background-color: #ffffff; // color of the underline
            }

            img {
                max-width: 100%;
                height: auto;
                margin-bottom: 10px;
            }

            span {
                display: block;
                color: #ffffff;
                font-size: 14px;
                position: relative;
                padding-bottom: 5px; // creates some space for the underline
            }
        }
    }
    @media screen and (min-width: 768px) {
        .article-list {
            display: flex;
            flex-direction: row;
            .four-grid-layout{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 20px;
                article{
                    width: 100%;
                    margin: 0;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    &:first-of-type{
                        grid-column: initial;
                        margin: initial;
                        .content{
                            width: 100%;
                            background: #FFF;
                            padding: 10px;
                            margin-top: -15px;
                            height: 43%;
                        }
                    }
                }
            }
        }
    }

}